import React from 'react';
import { connect } from 'react-redux'

// Material UI components
import { Grid } from '@material-ui/core';
import TurnoverBySemesters from './TurnoverBySemesters';
import TurnoverByYear from './TurnoverByYear';
import StatisticByYear from './StatisticByYear';

const Export = (props) => {
  const { actors } = props;

  return (
      <Grid container spacing={2} alignItems="stretch">
        <Grid item  xs={12} sm={6}>
            <TurnoverBySemesters
                actors={actors}
            
            />
        </Grid>
        <Grid item  xs={12} sm={6}>
            <TurnoverByYear
                actors={actors}
            />
        </Grid>
        <Grid item  xs={12} sm={12}>
            <StatisticByYear
                actors={actors}
            />
        </Grid>
      </Grid>
  )
}

let ExportConnected = connect(state => ({actors: state.actors}))(Export)
export default ExportConnected