import { setCall, handleResponse } from '../_helpers';

export const statementsServices = {
  statementsExport,
  statementsParamSeacrh,
  statementsActorSearch,
  lastStatementsSearch,
  statementsSearch,
  statementsInsert,
  statementsUpdate,
  statementsDelete,
  statementsPdf,
  yearlyStatementsPdf,
  sharesItems,
  statementsOverview,
  statementsXlsx,
  expensesExport,
  statementsVersions
}

async function statementsExport(id, from, to) {
  const query = (id === 0 || id === undefined) ? {from, to} : {actor_id: id, from, to}
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statements_export', query, "application/xlsx"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function expensesExport(id, from, to) {
  const query = (id === 0 || id === undefined) ? {from, to} : {actor_id: id, from, to}
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statements_export_by_actors', query, "application/xlsx"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function statementsParamSeacrh(id, from, to) {
  let requestOptions = {}
  if (id === 0 || id === undefined) {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'accounting_statements_search', {from, to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'accounting_statements_search', {actor_id: id, from, to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsActorSearch(id = '') {
  let requestOptions = {}
  if (id === '') {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'accounting_statements_search', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else { // The user is an admin
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'accounting_statements_search', {actor_id: id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function lastStatementsSearch(id="") {
  let requestOptions = {}
  if (id==="") {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'get_accounting_statement_data', {last_accounting_statement: true}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'get_accounting_statement_data', {actor_id: id, last_accounting_statement: true}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsSearch(id="", month, year) {
  let requestOptions = {}
  if (id==="") {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'get_accounting_statement_data', {month, year}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('accounting_statements', 'get_accounting_statement_data', {actor_id: id, month, year}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statement_insert', {...data}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

/**
 * 
 * @param {number} id 
 * @param {number} month 
 * @param {number} year 
 * @param {object} data -> {expenses: [...], shares: [...]}
 */
async function statementsUpdate(id, month, year, data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statement_update', {accounting_statement: data, keys: {actor_id: id, month, year}}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsDelete(id, month, year) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statement_remove', {actor_id: id, month, year}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsXlsx(id, year, month) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'get_accounting_statement_data', {actor_id: id, month, year, xlsx: true}, "application/xlsx"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function statementsPdf(id, year, month) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'get_accounting_statement_pdf', {actor_id: id, month, year}, "application/pdf"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"pdf")

  return json;
}

async function yearlyStatementsPdf(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'get_accounting_statement_pdf', data, "application/pdf"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"pdf")

  return json;
}

async function sharesItems(header_id) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statement_shares', 'get_accounting_statement_sharess', {expense_sheet_header_id: header_id}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsOverview(from = {month: 1, year: 2020}, to = {month: 1, year:2021}) {
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statements_overview', {from, to}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function statementsVersions(searchParams){
  const requestOptions = {
    method: 'POST',
    body: setCall('accounting_statements', 'accounting_statements_versions', searchParams),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}