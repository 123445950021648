import { setCall, handleResponse } from '../_helpers'

export const turnoversServices = {
  turnoversParamSearch,
  turnoversActorSearch,
  turnoversSearch,
  turnoversInsert,
  turnoversUpdate,
  turnoversDelete,
  turnoversXlsx,
  turnoversPdf,
  yearlyTurnoversPdf,
  turnoversOverview
}

async function turnoversParamSearch(id, from, to) {
  let requestOptions = {}
  if (id === 0 || id === undefined) {
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'turnovers_search', {from: from, to: to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'turnovers_search', {actor_id: id, from: from, to: to}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function turnoversActorSearch(id = '') {
  let requestOptions = {}
  if (id === '') { // if admin, search all, if not, get just the one from the current actor
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'turnovers_search', {}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else { // The user is an admin
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'turnovers_search', {actor_id: id}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function turnoversSearch(id, month, year) {
  let requestOptions = {}
  if (id==="") {
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'get_turnover_data', {month, year}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  } else {
    requestOptions = {
      method: 'POST',
      body: setCall('turnovers', 'get_turnover_data', {actor_id: id, month, year}),
      headers: {"Content-type": "application/json; charset=UTF-8" }
    }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function turnoversInsert(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'turnover_insert', {...data}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

/**
 * 
 * @param {number} id 
 * @param {number} month 
 * @param {number} year 
 * @param {number} harbour_id 
 * @param {object} data -> {expenses: [...], shares: [...]}
 */
async function turnoversUpdate(id, month, year, data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'turnover_update', {turnover: data, keys: {actor_id: id, month, year}}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function turnoversDelete(id, month, year) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'turnover_remove', {actor_id: id, month, year}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}

async function turnoversXlsx(id, year, month) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'get_turnover_data', {actor_id: id, month, year, xlsx: true}, "application/xlsx"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function turnoversPdf(id, year, month) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'get_turnover_pdf', {actor_id: id, month, year}, "application/pdf"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"pdf")

  return json;
}

async function yearlyTurnoversPdf(data) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'get_turnover_pdf', data, "application/pdf"),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"pdf")

  return json;
}

async function turnoversOverview(from = {month: 1, year: 2020}, to = {month: 1, year:2021}) {
  const requestOptions = {
    method: 'POST',
    body: setCall('turnovers', 'turnovers_overview', {from, to}),
    headers: {"Content-type": "application/json; charset=UTF-8" }
  }

  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response)

  return json;
}