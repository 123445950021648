import React, { useEffect, useState }  from 'react';

// Material UI components
import {
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Material UI icons
import {
  GridOn,
  Search,
  PictureAsPdf,
}  from '@material-ui/icons';

import { dic } from '../../_constants';

import XLSXExportViewer from './XLSXExportViewer'
import PDFViewer from './PDFViewer'

const ActorSelection = (props) => {
  const {selected, setSelected, actors, actorType} = props

  return actorType==="admin"
  ? (
      <FormControl variant="outlined" style={{width: "100%"}}>
        <InputLabel id="actor-select-outlined-label">{dic.ACTORS}</InputLabel>
        <Select
          labelId="actor-select-outlined-label"
          id="actor-select-outlined"
          label={dic.ACTORS}
          value={selected.actor_id}
          onChange={(e) => setSelected({...selected, actor_id: e.target.value})}
        >
          {actors.map((actor, key) => <MenuItem key={key} value={key}>{key < 1 ? dic.ALL : actor.corporate_name}</MenuItem>)}
        </Select>
      </FormControl>
  ) : null
}

const FilterDialog = (props) => {
  const { open, setOpen, selected, setSelectedDate, setSelected, actors, userData, setSearch } = props

  const [previousSelection, setPreviousSelection] = useState(null)
  const [openExportViewer, setOpenExportViewer] = useState(null)
  const [exportType, setExportType] = useState(false);
  const [yearly, setYearly] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (open) {
      setPreviousSelection(JSON.parse(JSON.stringify(selected)))
    }
  }, [open])
  
  useEffect(() => {
    if (exportType)
      setOpenExportViewer(true);
  }, [exportType])
  
  useEffect(() => {
    if (
      selected.year.from === selected.year.to &&
      selected.month.from === "01" && selected.month.to === "12" &&
      ((userData.actor.actor_type === "admin" && selected.actor_id > 0) || userData.actor.actor_type === "pilots")
    ) {
      setYearly(true)
    }
    else
      setYearly(false)
  }, [selected, userData])

  const handleClose = () => {
    setSelected(previousSelection)
    setOpen(false)
  }
  const handleSearch = () => {
    setSearch(true)
    setOpen(false)
  }

  const getStatementsExport = async () => {
    setExportType('statements')
  }

  const getExpensesExport = async () => {
    setExportType('expenses')
  }

  const getYearlyStatement = async () => {
    setOpenDialog(true)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle color="primary">{dic.FILTERING_OPTIONS}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
              <ActorSelection
                actorType={ userData ? userData.actor.actor_type : "pilot" }
                selected={ selected }
                setSelected={ setSelected }
                actors={ actors } />
          </Grid>
          <Grid item xs={6} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                <DatePicker
                  label={dic.FROM}
                  variant="inline"
                  inputVariant="outlined"
                  openTo="year"
                  views={["year", "month"]}
                  value={new Date(Date.parse(selected.year.from + "-" + selected.month.from + "-01"))}
                  onChange={(val) => { setSelectedDate("from", val) }}
                />
              </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <DatePicker
                label={dic.TO}
                variant="inline"
                inputVariant="outlined"
                openTo="year"
                views={["year", "month"]}
                value={new Date(Date.parse(selected.year.to + "-" + selected.month.to + "-01"))}
                onChange={(val) => { setSelectedDate("to", val) }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <XLSXExportViewer 
          data={selected}
          open={openExportViewer}
          setOpen={setOpenExportViewer}
          actors={actors}
          exportType={exportType}
          setExportType={setExportType}
        />
        <PDFViewer
          open = { openDialog }
          setOpen = { setOpenDialog }
          data = {{ 
            corporate_name: selected.actor_id > 0 ? actors[selected.actor_id].corporate_name : '',
            from: { month: selected.month.from, year: selected.year.from },
            to: { month: selected.month.to, year: selected.year.to },
            actor_id: selected.actor_id > 0 ? actors[selected.actor_id].id : null,
            yearly: true
           }}
        />
        <Grid container spacing={2} justifyContent="center" style={{padding: 30}}>
          <Grid item>
            <Button onClick={handleClose} variant="outlined">
              {dic.CANCEL}
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<GridOn />} onClick={getStatementsExport} variant="outlined" color="primary">
              {dic.XLSX} {dic.ACCOUNTING_STATEMENTS}
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<GridOn />} onClick={getExpensesExport} variant="outlined" color="primary">
              {dic.XLSX} {dic.EXPENSES_SHEET}
            </Button>
          </Grid>
          {yearly &&
            <Grid item>
              <Button startIcon={<PictureAsPdf />} onClick={getYearlyStatement} variant="outlined" color="secondary">
                {dic.ACCOUNTING_STATEMENT} ANNUALE
              </Button>
            </Grid>
          }
          <Grid item>
            <Button startIcon={<Search />} onClick={handleSearch} variant="outlined" color="primary">
              {dic.SEARCH}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default FilterDialog